import React, {useState} from "react";
import {Country, CountryCode, useQueryGoogleCurrenciesQuery} from "../generated/graphql";
import {Col, FormControl, InputGroup, Row} from "react-bootstrap";
import chunk from "lodash/chunk";
import {ChangeEvent} from "react";
import styles from "./Diff.module.css"
import {useCookies} from "react-cookie";


export default function Discounts({
                                      discounts,
                                      setDiscounts
                                  }: { discounts: Map<CountryCode, number> | undefined, setDiscounts: React.Dispatch<React.SetStateAction<Map<CountryCode, number> | undefined>> }) {
    const {loading, error, data} = useQueryGoogleCurrenciesQuery();



    if (!data) {
        return <Row>
            <Col>
                {loading && <p>Loading</p>}
                {error && <p>Error!</p>}
            </Col>
        </Row>
    }

    const chunkSize = Math.floor(data.googleCurrencyList.length / 5);
    const chunks = chunk(data.googleCurrencyList, chunkSize);

    let chunkIdx = 0;
    const refs: { country: CountryCode, input: HTMLInputElement }[] = [];

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const discounts = new Map<CountryCode, number>();
        for (const ref of refs) {
            let value = ref.input.value;
            if (value == null || value == "") continue;
            const val = parseInt(value);
            if (!isNaN(val)) {
                discounts.set(ref.country, val);
            }
        }
        setDiscounts(discounts);
    }

    return <Row>
        {chunks.map(chunk => {
            return <Col key={chunkIdx++}>
                {chunk.map(el => {
                    const currentDiscount = discounts?.get(el.country.alpha2);
                    return <div key={el.country.alpha2}>
                        <small>{el.country.shortName}</small>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id={`country-${el.country.alpha2}`}>
                                    {el.country.alpha2} | {el.currency.code}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0%"
                                isValid={currentDiscount != null ? true : undefined}
                                value={currentDiscount != null ? currentDiscount : ""}
                                aria-label="Discount percent"
                                aria-describedby={`country-${el.country.alpha2}`}
                                onChange={onChange}
                                ref={(ref: HTMLInputElement) => {
                                    refs.push({country: el.country.alpha2, input: ref})
                                }}
                            />
                        </InputGroup>
                    </div>
                })}
            </Col>
        })}
            </Row>
        }