import FileSaver from "file-saver";
import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import {ConvertMutationMutation} from "../generated/graphql";
import Diff from "./Diff";

export default function Result(props:
                                   {
                                       data: ConvertMutationMutation
                                   }
) {
    const downloadFile = () => {
        const blob = new Blob([props.data.convert!.csv], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "converted-in-app-products.csv");
    }

    return <Row>
        <Col>
            <Row>
                <Col>
                    <Button variant="primary" onClick={downloadFile}>Download result CSV</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Diff data={props.data}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" onClick={downloadFile}>Download result</Button>
                </Col>
            </Row>
        </Col>
    </Row>
}