import {Alert, Button, Card, Col, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {ConvertMutationMutation} from "../generated/graphql";
import styles from "./Diff.module.css"
import {symlink} from "fs";


function ProductDiff({productResult}: { productResult: ConvertMutationMutation["convert"]["convertedProductResults"][0] }) {
    const [showNotChanged, setShowNotChanged] = useState<boolean>();
    const [collapsed, setCollapsed] = useState<boolean>();

    const managedByPricingTemplate = productResult.product.pricingTemplateID != null && productResult.product.pricingTemplateID != "";

    return <Card className="my-3">
        <Card.Header>
            <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCollapsed(!collapsed)
                return false;
            }}>
                <Row className={"align-items-center"}>
                    <Col>
                        <h3> {productResult.product.productId}</h3>
                        {managedByPricingTemplate ? `Managed by pricing template ${productResult.product.pricingTemplateID}` : undefined}
                    </Col>
                    <Col md="auto">
                        <Button variant="info"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowNotChanged(!showNotChanged)
                                    return false;
                                }}>{!showNotChanged ? "Show not changed" : "Hide not changed"}</Button>
                    </Col>
                    <Col md="auto">
                        {collapsed ? "▲" : "▼"}
                    </Col>
                </Row>
            </span>
        </Card.Header>
        {!managedByPricingTemplate && !collapsed &&
        <Card.Body>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Currency</th>
                    <th>Old Price</th>
                    <th>New Price</th>
                    <th>New price percent difference</th>
                    <th>Discount</th>
                    <th>New Price Before Beauty</th>
                    <th>From Apple</th>
                    <th>Conversion Rate</th>
                    <th>USD price</th>
                </tr>
                </thead>
                <tbody>
                {productResult.data.map(productDiff => {

                    // const pricesDiffer = productDiff.value.oldPrice != productDiff.value.newPrice;
                    const ratioDiff = (productDiff.value.newPrice / productDiff.value.oldPrice) - 1;
                    const klass = ratioDiff == 0 ? undefined : ratioDiff < 0 ? styles.red : styles.green;
                    const percentDiff = ratioDiff * 100;
                    // Round to 2 decimals
                    const roundedPercent = Math.round(percentDiff * 100) / 100;
                    if (ratioDiff == 0 && !showNotChanged) {
                        return undefined;
                    }

                    return <tr key={productDiff.key.alpha2}>
                        <td>
                            <small>{productDiff.key.shortName}</small>
                            <p>{productDiff.key.alpha2} | {productDiff.value.googleCurrency.code}</p>
                        </td>
                        <td>{productDiff.value.oldPrice}</td>
                        <td>{productDiff.value.newPrice}</td>
                        <td className={klass}>{roundedPercent}%</td>
                        <td>{productDiff.value.discount}</td>
                        <td>{productDiff.value.newPriceBeforeBeauty}</td>
                        {productDiff.value.fromApple ? <td>Tier: {productDiff.value.appleTier} /
                            Price: {productDiff.value.applePrice}</td> : <td>False</td>}
                        <td>{productDiff.value.conversionFailed ? "Failed" : productDiff.value.conversionRate}</td>
                        <td>{productResult.usdPrice}</td>
                    </tr>
                }).filter(el => el != null)}
                </tbody>
            </Table>
        </Card.Body>
        }
    </Card>

}


export default function Diff(props: { data: ConvertMutationMutation }) {


    return <>
        {props.data.convert!.convertedProductResults.map(productResult => {
            return <div key={productResult.product.productId}>
                <ProductDiff productResult={productResult}/>
            </div>
        })}
    </>
}