import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
};



export type ApplePricePoint = {
  __typename?: 'ApplePricePoint';
  currency: Scalars['String'];
  customerPrice: Scalars['String'];
  priceTier: Scalars['String'];
  proceeds: Scalars['String'];
  territory: Scalars['String'];
};

export type ConvertDataResult = {
  __typename?: 'ConvertDataResult';
  csv: Scalars['String'];
  convertedProductResults: Array<ConvertedProductResult>;
};

export type ConvertParamsInput = {
  csv: Scalars['String'];
  discounts?: Maybe<Array<KeyValuePairOfStringAndDecimalInput>>;
};

export type ConvertedProductResult = {
  __typename?: 'ConvertedProductResult';
  usdPrice: Scalars['Decimal'];
  product: GoogleProduct;
  data: Array<KeyValuePairOfCountryAndConvertedProductResultData>;
};

export type ConvertedProductResultData = {
  __typename?: 'ConvertedProductResultData';
  oldPrice: Scalars['Decimal'];
  newPrice: Scalars['Decimal'];
  newPriceBeforeBeauty: Scalars['Decimal'];
  fromApple: Scalars['Boolean'];
  appleTier: Scalars['Int'];
  applePrice: Scalars['Decimal'];
  conversionRate: Scalars['Decimal'];
  conversionFailed: Scalars['Boolean'];
  discount: Scalars['Decimal'];
  googleCurrency: Currency;
};

export type Country = {
  __typename?: 'Country';
  shortName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  activeDirectoryName?: Maybe<Scalars['String']>;
  alpha2: CountryCode;
  alpha3: CountryCodeAlpha3;
  numeric?: Maybe<Scalars['Int']>;
};

export enum CountryCode {
  None = 'NONE',
  Aw = 'AW',
  Af = 'AF',
  Ao = 'AO',
  Ai = 'AI',
  Ax = 'AX',
  Al = 'AL',
  Ad = 'AD',
  Ae = 'AE',
  Ar = 'AR',
  Am = 'AM',
  As = 'AS',
  Aq = 'AQ',
  Tf = 'TF',
  Ag = 'AG',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bi = 'BI',
  Be = 'BE',
  Bj = 'BJ',
  Bf = 'BF',
  Bd = 'BD',
  Bg = 'BG',
  Bh = 'BH',
  Bs = 'BS',
  Ba = 'BA',
  Bl = 'BL',
  By = 'BY',
  Bz = 'BZ',
  Bm = 'BM',
  Bo = 'BO',
  Br = 'BR',
  Bb = 'BB',
  Bn = 'BN',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  Cf = 'CF',
  Ca = 'CA',
  Cc = 'CC',
  Ch = 'CH',
  Cl = 'CL',
  Cn = 'CN',
  Ci = 'CI',
  Cm = 'CM',
  Cd = 'CD',
  Cg = 'CG',
  Ck = 'CK',
  Co = 'CO',
  Km = 'KM',
  Cv = 'CV',
  Cr = 'CR',
  Cu = 'CU',
  Cw = 'CW',
  Cx = 'CX',
  Ky = 'KY',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dm = 'DM',
  Dk = 'DK',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Eg = 'EG',
  Er = 'ER',
  Eh = 'EH',
  Es = 'ES',
  Ee = 'EE',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fr = 'FR',
  Fo = 'FO',
  Fm = 'FM',
  Ga = 'GA',
  Gb = 'GB',
  Ge = 'GE',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gn = 'GN',
  Gp = 'GP',
  Gm = 'GM',
  Gw = 'GW',
  Gq = 'GQ',
  Gr = 'GR',
  Gd = 'GD',
  Gl = 'GL',
  Gt = 'GT',
  Gf = 'GF',
  Gu = 'GU',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Ie = 'IE',
  Ir = 'IR',
  Iq = 'IQ',
  Is = 'IS',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Je = 'JE',
  Jo = 'JO',
  Jp = 'JP',
  Kz = 'KZ',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Kn = 'KN',
  Kr = 'KR',
  Xk = 'XK',
  Kw = 'KW',
  La = 'LA',
  Lb = 'LB',
  Lr = 'LR',
  Ly = 'LY',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Mo = 'MO',
  Mf = 'MF',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Mg = 'MG',
  Mv = 'MV',
  Mx = 'MX',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mt = 'MT',
  Mm = 'MM',
  Me = 'ME',
  Mn = 'MN',
  Mp = 'MP',
  Mz = 'MZ',
  Mr = 'MR',
  Ms = 'MS',
  Mq = 'MQ',
  Mu = 'MU',
  Mw = 'MW',
  My = 'MY',
  Yt = 'YT',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nu = 'NU',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nz = 'NZ',
  Om = 'OM',
  Pk = 'PK',
  Pa = 'PA',
  Pn = 'PN',
  Pe = 'PE',
  Ph = 'PH',
  Pw = 'PW',
  Pg = 'PG',
  Pl = 'PL',
  Pr = 'PR',
  Kp = 'KP',
  Pt = 'PT',
  Py = 'PY',
  Ps = 'PS',
  Pf = 'PF',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sd = 'SD',
  Sn = 'SN',
  Sg = 'SG',
  Gs = 'GS',
  Sj = 'SJ',
  Sb = 'SB',
  Sl = 'SL',
  Sv = 'SV',
  Sm = 'SM',
  So = 'SO',
  Pm = 'PM',
  Rs = 'RS',
  Ss = 'SS',
  St = 'ST',
  Sr = 'SR',
  Sk = 'SK',
  Si = 'SI',
  Se = 'SE',
  Sz = 'SZ',
  Sx = 'SX',
  Sc = 'SC',
  Sy = 'SY',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tm = 'TM',
  Tl = 'TL',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ug = 'UG',
  Ua = 'UA',
  Um = 'UM',
  Uy = 'UY',
  Us = 'US',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
  Bq = 'BQ',
  Sh = 'SH',
  Cs = 'CS'
}

export enum CountryCodeAlpha3 {
  None = 'NONE',
  Abw = 'ABW',
  Afg = 'AFG',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  Alb = 'ALB',
  And = 'AND',
  Are = 'ARE',
  Arg = 'ARG',
  Arm = 'ARM',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Aut = 'AUT',
  Aze = 'AZE',
  Bdi = 'BDI',
  Bel = 'BEL',
  Ben = 'BEN',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bra = 'BRA',
  Brb = 'BRB',
  Brn = 'BRN',
  Btn = 'BTN',
  Bvt = 'BVT',
  Bwa = 'BWA',
  Caf = 'CAF',
  Can = 'CAN',
  Cck = 'CCK',
  Che = 'CHE',
  Chl = 'CHL',
  Chn = 'CHN',
  Civ = 'CIV',
  Cmr = 'CMR',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Com = 'COM',
  Cpv = 'CPV',
  Cri = 'CRI',
  Cub = 'CUB',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cze = 'CZE',
  Deu = 'DEU',
  Dji = 'DJI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dza = 'DZA',
  Ecu = 'ECU',
  Egy = 'EGY',
  Eri = 'ERI',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Eth = 'ETH',
  Fin = 'FIN',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gbr = 'GBR',
  Geo = 'GEO',
  Ggy = 'GGY',
  Gha = 'GHA',
  Gib = 'GIB',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guf = 'GUF',
  Gum = 'GUM',
  Guy = 'GUY',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  Iot = 'IOT',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  Isr = 'ISR',
  Ita = 'ITA',
  Jam = 'JAM',
  Jey = 'JEY',
  Jor = 'JOR',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Ken = 'KEN',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kna = 'KNA',
  Kor = 'KOR',
  Kos = 'KOS',
  Kwt = 'KWT',
  Lao = 'LAO',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lie = 'LIE',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Lva = 'LVA',
  Mac = 'MAC',
  Maf = 'MAF',
  Mar = 'MAR',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mhl = 'MHL',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Moz = 'MOZ',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Ncl = 'NCL',
  Ner = 'NER',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Niu = 'NIU',
  Nld = 'NLD',
  Nor = 'NOR',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Omn = 'OMN',
  Pak = 'PAK',
  Pan = 'PAN',
  Pcn = 'PCN',
  Per = 'PER',
  Phl = 'PHL',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  Pyf = 'PYF',
  Qat = 'QAT',
  Reu = 'REU',
  Rou = 'ROU',
  Rus = 'RUS',
  Rwa = 'RWA',
  Sau = 'SAU',
  Sdn = 'SDN',
  Sen = 'SEN',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slv = 'SLV',
  Smr = 'SMR',
  Som = 'SOM',
  Spm = 'SPM',
  Srb = 'SRB',
  Ssd = 'SSD',
  Stp = 'STP',
  Sur = 'SUR',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Ton = 'TON',
  Tto = 'TTO',
  Tun = 'TUN',
  Tur = 'TUR',
  Tuv = 'TUV',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Ukr = 'UKR',
  Umi = 'UMI',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Vgb = 'VGB',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Zaf = 'ZAF',
  Zmb = 'ZMB',
  Zwe = 'ZWE',
  Bes = 'BES',
  Shn = 'SHN',
  Scg = 'SCG'
}

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  decimalDigits: Scalars['Float'];
  englishName?: Maybe<Scalars['String']>;
  majorUnit: Scalars['Decimal'];
  minorUnit: Scalars['Decimal'];
};


export type GoogleCurrencyData = {
  __typename?: 'GoogleCurrencyData';
  currency: Currency;
  country: Country;
  currencyName: Scalars['String'];
  rangeMin: Scalars['Decimal'];
  rangeMax: Scalars['Decimal'];
};

export type GoogleProduct = {
  __typename?: 'GoogleProduct';
  productId: Scalars['String'];
  publishedState: Scalars['String'];
  purchaseType: Scalars['String'];
  autoTranslate: Scalars['Boolean'];
  localeTitleDescription: Scalars['String'];
  autoFillPrices: Scalars['Boolean'];
  price: Scalars['String'];
  prices: Array<KeyValuePairOfStringAndPriceData>;
  pricingTemplateID: Scalars['String'];
};

export type KeyValuePairOfCountryAndConvertedProductResultData = {
  __typename?: 'KeyValuePairOfCountryAndConvertedProductResultData';
  key: Country;
  value: ConvertedProductResultData;
};

export type KeyValuePairOfStringAndDecimalInput = {
  key: Scalars['String'];
  value: Scalars['Decimal'];
};

export type KeyValuePairOfStringAndPriceData = {
  __typename?: 'KeyValuePairOfStringAndPriceData';
  key: Scalars['String'];
  value: PriceData;
};


export type Mutation = {
  __typename?: 'Mutation';
  convert: ConvertDataResult;
};


export type MutationConvertArgs = {
  input: ConvertParamsInput;
};

export type PriceData = {
  __typename?: 'PriceData';
  priceFromCsv: Scalars['String'];
  priceInMilliUnits: Scalars['Long'];
  priceWhole: Scalars['Decimal'];
};

export type Query = {
  __typename?: 'Query';
  appstorePrices: Array<ApplePricePoint>;
  googleCurrencyList: Array<GoogleCurrencyData>;
};

export type ConvertMutationMutationVariables = Exact<{
  input: ConvertParamsInput;
}>;


export type ConvertMutationMutation = (
  { __typename?: 'Mutation' }
  & { convert: (
    { __typename?: 'ConvertDataResult' }
    & Pick<ConvertDataResult, 'csv'>
    & { convertedProductResults: Array<(
      { __typename?: 'ConvertedProductResult' }
      & Pick<ConvertedProductResult, 'usdPrice'>
      & { product: (
        { __typename?: 'GoogleProduct' }
        & Pick<GoogleProduct, 'autoFillPrices' | 'autoTranslate' | 'localeTitleDescription' | 'price' | 'pricingTemplateID' | 'productId' | 'publishedState' | 'purchaseType'>
        & { prices: Array<(
          { __typename?: 'KeyValuePairOfStringAndPriceData' }
          & Pick<KeyValuePairOfStringAndPriceData, 'key'>
          & { value: (
            { __typename?: 'PriceData' }
            & Pick<PriceData, 'priceFromCsv' | 'priceInMilliUnits' | 'priceWhole'>
          ) }
        )> }
      ), data: Array<(
        { __typename?: 'KeyValuePairOfCountryAndConvertedProductResultData' }
        & { key: (
          { __typename?: 'Country' }
          & Pick<Country, 'activeDirectoryName' | 'alpha2' | 'alpha3' | 'fullName' | 'numeric' | 'shortName'>
        ), value: (
          { __typename?: 'ConvertedProductResultData' }
          & Pick<ConvertedProductResultData, 'applePrice' | 'appleTier' | 'conversionFailed' | 'conversionRate' | 'discount' | 'fromApple' | 'newPrice' | 'newPriceBeforeBeauty' | 'oldPrice'>
          & { googleCurrency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'code' | 'decimalDigits' | 'englishName' | 'majorUnit' | 'minorUnit' | 'namespace' | 'number' | 'symbol'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type QueryApplePricesQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryApplePricesQuery = (
  { __typename?: 'Query' }
  & { appstorePrices: Array<(
    { __typename?: 'ApplePricePoint' }
    & Pick<ApplePricePoint, 'currency' | 'customerPrice' | 'priceTier' | 'proceeds' | 'territory'>
  )> }
);

export type QueryGoogleCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryGoogleCurrenciesQuery = (
  { __typename?: 'Query' }
  & { googleCurrencyList: Array<(
    { __typename?: 'GoogleCurrencyData' }
    & Pick<GoogleCurrencyData, 'currencyName'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'alpha2' | 'fullName' | 'shortName'>
    ), currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'code' | 'decimalDigits'>
    ) }
  )> }
);


export const ConvertMutationDocument = gql`
    mutation ConvertMutation($input: ConvertParamsInput!) {
  convert(input: $input) {
    csv
    convertedProductResults {
      product {
        autoFillPrices
        autoTranslate
        localeTitleDescription
        price
        prices {
          key
          value {
            priceFromCsv
            priceInMilliUnits
            priceWhole
          }
        }
        pricingTemplateID
        productId
        publishedState
        purchaseType
      }
      data {
        key {
          activeDirectoryName
          alpha2
          alpha3
          fullName
          numeric
          shortName
        }
        value {
          applePrice
          appleTier
          conversionFailed
          conversionRate
          discount
          fromApple
          newPrice
          newPriceBeforeBeauty
          oldPrice
          googleCurrency {
            code
            decimalDigits
            englishName
            majorUnit
            minorUnit
            namespace
            number
            symbol
          }
        }
      }
      usdPrice
    }
  }
}
    `;
export type ConvertMutationMutationFn = Apollo.MutationFunction<ConvertMutationMutation, ConvertMutationMutationVariables>;

/**
 * __useConvertMutationMutation__
 *
 * To run a mutation, you first call `useConvertMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertMutationMutation, { data, loading, error }] = useConvertMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertMutationMutation(baseOptions?: Apollo.MutationHookOptions<ConvertMutationMutation, ConvertMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertMutationMutation, ConvertMutationMutationVariables>(ConvertMutationDocument, options);
      }
export type ConvertMutationMutationHookResult = ReturnType<typeof useConvertMutationMutation>;
export type ConvertMutationMutationResult = Apollo.MutationResult<ConvertMutationMutation>;
export type ConvertMutationMutationOptions = Apollo.BaseMutationOptions<ConvertMutationMutation, ConvertMutationMutationVariables>;
export const QueryApplePricesDocument = gql`
    query QueryApplePrices {
  appstorePrices {
    currency
    customerPrice
    priceTier
    proceeds
    territory
  }
}
    `;

/**
 * __useQueryApplePricesQuery__
 *
 * To run a query within a React component, call `useQueryApplePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryApplePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryApplePricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryApplePricesQuery(baseOptions?: Apollo.QueryHookOptions<QueryApplePricesQuery, QueryApplePricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryApplePricesQuery, QueryApplePricesQueryVariables>(QueryApplePricesDocument, options);
      }
export function useQueryApplePricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryApplePricesQuery, QueryApplePricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryApplePricesQuery, QueryApplePricesQueryVariables>(QueryApplePricesDocument, options);
        }
export type QueryApplePricesQueryHookResult = ReturnType<typeof useQueryApplePricesQuery>;
export type QueryApplePricesLazyQueryHookResult = ReturnType<typeof useQueryApplePricesLazyQuery>;
export type QueryApplePricesQueryResult = Apollo.QueryResult<QueryApplePricesQuery, QueryApplePricesQueryVariables>;
export const QueryGoogleCurrenciesDocument = gql`
    query QueryGoogleCurrencies {
  googleCurrencyList {
    country {
      alpha2
      fullName
      shortName
    }
    currency {
      code
      decimalDigits
    }
    currencyName
  }
}
    `;

/**
 * __useQueryGoogleCurrenciesQuery__
 *
 * To run a query within a React component, call `useQueryGoogleCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGoogleCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGoogleCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGoogleCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<QueryGoogleCurrenciesQuery, QueryGoogleCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGoogleCurrenciesQuery, QueryGoogleCurrenciesQueryVariables>(QueryGoogleCurrenciesDocument, options);
      }
export function useQueryGoogleCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGoogleCurrenciesQuery, QueryGoogleCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGoogleCurrenciesQuery, QueryGoogleCurrenciesQueryVariables>(QueryGoogleCurrenciesDocument, options);
        }
export type QueryGoogleCurrenciesQueryHookResult = ReturnType<typeof useQueryGoogleCurrenciesQuery>;
export type QueryGoogleCurrenciesLazyQueryHookResult = ReturnType<typeof useQueryGoogleCurrenciesLazyQuery>;
export type QueryGoogleCurrenciesQueryResult = Apollo.QueryResult<QueryGoogleCurrenciesQuery, QueryGoogleCurrenciesQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    