import React, {ChangeEvent, MouseEventHandler, useState} from "react";
import useAsyncEffect from "use-async-effect";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useConvertMutationMutation} from "../generated/graphql";
import Result from "./Result";
import styles from "./Converter.module.css"

const FileUploader = (props: { handleFiles: (files: FileList) => void }) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        hiddenFileInput.current?.click();
    };

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filesUploaded = event.target.files!;
        props.handleFiles(filesUploaded);
    };
    return (
        <>
            <Button onClick={handleClick}>
                Upload google in-app products csv
            </Button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}
            />
        </>
    );
}


export default function Converter({
                                      setFileData,
                                      fileData
                                  }: { setFileData: React.Dispatch<React.SetStateAction<FileList | undefined>>, fileData: FileList | undefined }) {


    return <Row>
        <Col>
            <Form>
                <Form.Group>
                    <FileUploader handleFiles={(files) => {
                        setFileData(files)
                    }}/>
                </Form.Group>
            </Form>
        </Col>
    </Row>
}