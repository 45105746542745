import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
} from "@apollo/client";

import 'bootstrap/dist/css/bootstrap.min.css';
import {CookiesProvider} from "react-cookie";


const url = "/graphql";

const client = new ApolloClient({
    uri: url,
    cache: new InMemoryCache()
});


ReactGA.initialize('G-07LGXT2V30');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
