import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Jumbotron, Row} from "react-bootstrap";
import Converter from "./components/Converter";
import styles from "./App.module.css"
import {CountryCode, KeyValuePairOfStringAndDecimalInput, useConvertMutationMutation} from "./generated/graphql";
import useAsyncEffect from "use-async-effect";
import Result from "./components/Result";
import Discounts from "./components/Discounts";
import {useCookies} from "react-cookie";
import isEmpty from "lodash/isEmpty"

function replacer(key: string, value: any) {
    if(value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}


function reviver(key: string, value:any) {
    if(typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

function App() {
    const [fileData, setFileData] = useState<FileList | undefined>();
    const [discounts, setDiscounts] = useState<Map<CountryCode, number>>();
    const [reloadTime, setReloadTime] = useState<Date>(new Date());

    const [convertMutationMutation, {data, loading, error}] = useConvertMutationMutation();
    const [cookies, setCookie, removeCookie] = useCookies([]);


    useAsyncEffect(async isMounted => {
        if (fileData == null) return;

        const data = await fileData![0].arrayBuffer();
        if (!isMounted()) return;
        const uploadedData = new TextDecoder().decode(data);
        console.log("Waiting for mutation");
        let mutationDiscounts: Array<KeyValuePairOfStringAndDecimalInput> = [];

        if (discounts != null) {
            discounts.forEach((value, key, map) => {
                mutationDiscounts.push({
                    key: key.toString(),
                    value: value / 100
                })
            })
        }

        await convertMutationMutation({
            variables: {
                input: {
                    csv: uploadedData,
                    discounts: mutationDiscounts
                }
            },
        });
        console.log("Mutation done");

    }, [fileData, reloadTime])

    useEffect(() => {
        if (discounts != null) {
            let discountsJson = JSON.stringify(discounts, replacer);
            console.log("Saving discounts", discountsJson)
            setCookie('discounts', discountsJson, {path: '/', secure: true, sameSite: "strict"});
        } else {
            removeCookie('discounts')
        }
    }, [discounts]);

    useEffect(() => {
        try {
            // useCookie всегда парсит джейсон :facepalm:
            let cookie = JSON.stringify(cookies['discounts']);
            if (discounts == null && cookie != null) {
                console.log(cookie);
                let parsedDiscounts = JSON.parse(cookie, reviver);
                console.log(parsedDiscounts);
                setDiscounts(parsedDiscounts);
            }
        }catch (e) {
            console.error("Error parsing discounts");
            console.error(e);
            removeCookie('discounts')
        }
    }, [])


    return <Container className="p-3">
        <Row>
            <Col>
                <Row className="align-content-end">
                    <Col>
                    </Col>
                </Row>
                <Jumbotron>
                    <Row className="align-items-baseline">
                        <Col>
                            <h1>Convert your Google prices csv to Apple price tiers</h1>
                            <p>
                                Upload your Google Play in-app products export csv, and you will get a csv with prices
                                converted to Apple's price points, and a diff of what
                                changed.
                            </p>
                            <hr/>
                            <p>How it works:</p>
                            <p>On the input side Price Converter have Apple's fixed <a
                                href="https://developer.apple.com/documentation/appstoreconnectapi/list_app_price_points">price
                                points for in-app products</a> and <a
                                href="https://support.google.com/googleplay/android-developer/answer/1153481?hl=en#zippy=%2Ccreate-a-batch-of-multiple-in-app-products">export
                                of your Google Play in-app products</a></p>
                            <p>For each product Price Converter will try to find the closest apple price tier for USD
                                price of your Google Play in-app product</p>
                            <p>It will then set prices for <a
                                href="https://support.google.com/googleplay/android-developer/answer/10532353?hl=en">every
                                country Google Play supports</a> to one of:</p>
                            <ul>
                                <li>Apple price point price — if Apple support this country</li>
                                <li>Converted price from USD to the country's currency (using currency conversion)
                                    (Google Play supports more countries than Apple)
                                </li>
                            </ul>
                            <p>It will then apply any discounts you set and beautify the price (to be .59/.99 and so
                                on)</p>
                            <hr/>
                        </Col>
                        <Col md="auto">
                            <Button variant="light" href="/applePricePoints.json">Download apple
                                price points
                                json (last updated 2022-03-21)</Button>
                        </Col>
                    </Row>
                </Jumbotron>
            </Col>
        </Row>
        <Row>
            <Col>
                {!loading &&
                <Converter setFileData={setFileData} fileData={fileData}/>}
            </Col>
        </Row>
        <Row className="my-3">
            <Col>
                <Card>
                    <Card.Header className={"p-3"}>
                        <Row className={"align-items-center"}>
                            <Col>
                                <Row className={"align-items-center"}>
                                    <Col md="auto">
                                        Discounts
                                        <br/>
                                        <small>discounts will persists between reloads</small>
                                    </Col>
                                    <Col>
                                <Button variant={"dark"} onClick={() => { setDiscounts(undefined)}}>Reset</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="auto">
                                {data ? <Button onClick={() => setReloadTime(new Date())}>Reload prices with discounts</Button> : "First upload csv. You will be able to reload prices after change."}

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body><Discounts discounts={discounts} setDiscounts={setDiscounts}/></Card.Body>
                    {data &&
                    <Card.Footer><Button onClick={() => setReloadTime(new Date())}>Reload prices with discounts</Button></Card.Footer>}
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                {loading && <p>Loading</p>}
                {error && <p>Error</p>}
                {data && <Result data={data}/>}
            </Col>
        </Row>
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <Row>
                <Col className="col-md-12">
                    <img className="mb-2" src="https://fx.gl/favicon.ico" alt="" width="24" height="24"/>
                    <small className="d-block mb-3 text-muted">&copy; Michael Varamashvili @ <a href="https://fx.gl">Fx
                        Games</a> {new Date().getFullYear()}</small>
                </Col>
                <Col>
                    <p className={styles.disclaimer}>THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND,
                        EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                        A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE
                        LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
                        OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
                        IN THE SOFTWARE.</p>
                </Col>
            </Row>
        </footer>
    </Container>
}

export default App;
